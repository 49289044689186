export const SET_INSTALLED = "STATUS_SET_INSTALLED";
export const SET_SERVER_ONLINE = "STATUS_SET_SERVER_ONLINE";
export const SET_APP_INITIALIZED = "STATUS_SET_APP_INITIALIZED";
export const SET_LOCATION_DATA = "STATUS_SET_LOCATION_DATA";
export const SET_SESSION_DATA = "STATUS_SET_SESSION_DATA";
export const SET_FONTS_READY = "STATUS_SET_FONTS_READY";
export const SET_LIBRARIES_READY = "STATUS_SET_LIBRARIES_READY";
export const SET_MESSAGE_READ = "STATUS_SET_MESSAGE_READ";
export const ADD_FAVOURITE = "STATUS_ADD_FAVOURITE";
export const DELETE_FAVOURITE = "STATUS_DELETE_FAVOURITE";
export const SET_WELCOMED = "STATUS_SET_WELCOMED";
export const SET_ALARM = "STATUS_SET_ALARM";
export const UPDATE_STATUS = "STATUS_UPDATE";
export const STATUS_UPDATE_VIA_MQTT = "STATUS_UPDATE_VIA_MQTT";
export const CONNECTION_ERROR = "STATUS_CONNECTION_ERROR";
export const SET_TV_APP_OUTDATED = "STATUS_TV_APP_OUTDATED";
export const MESSAGES_NOT_SHOWN = "MESSAGES_NOT_SHOWN";
export const SET_MESSAGES = "SET_MESSAGES";
export const UPDATE_POWER_STATUS = "UPDATE_POWER_STATUS";
export const CENTER_FULL_SCREEN_MESSAGE = "CENTER_FULL_SCREEN_MESSAGE";

export function setInstalled(val) {
    return (dispatch) => {
        dispatch({
            type: SET_INSTALLED,
            payload: val,
        });
    };
}

export function setServerOnline(val) {
    return (dispatch) => {
        dispatch({
            type: SET_SERVER_ONLINE,
            payload: val,
        });
    };
}
export function connectionError() {
    return (dispatch) => {
        dispatch({
            type: CONNECTION_ERROR,
        });
    };
}

export function setAppInitialized() {
    return (dispatch) => {
        dispatch({
            type: SET_APP_INITIALIZED,
        });
    };
}
export function setLocationData(data) {
    return (dispatch) => {
        dispatch({
            type: SET_LOCATION_DATA,
            payload: data,
        });
    };
}
export function setSessionData(data) {
    return (dispatch) => {
        dispatch({
            type: SET_SESSION_DATA,
            payload: data,
        });
    };
}
export function setFontsReady(data) {
    return (dispatch) => {
        dispatch({
            type: SET_FONTS_READY,
            payload: data,
        });
    };
}
export function setLibrariesReady(data) {
    return (dispatch) => {
        dispatch({
            type: SET_LIBRARIES_READY,
            payload: data,
        });
    };
}
export function setMessageRead(msgId) {
    return (dispatch) => {
        dispatch({
            type: SET_MESSAGE_READ,
            payload: msgId,
        });
    };
}
export function addFavourite(chId) {
    return (dispatch) => {
        dispatch({
            type: ADD_FAVOURITE,
            payload: chId,
        });
    };
}

export function deleteFavourite(chId) {
    return (dispatch) => {
        dispatch({
            type: DELETE_FAVOURITE,
            payload: chId,
        });
    };
}
export function setWelcomed() {
    return (dispatch) => {
        dispatch({
            type: SET_WELCOMED,
        });
    };
}
export function setAlarm(alarm) {
    return (dispatch) => {
        dispatch({
            type: SET_ALARM,
            payload: alarm,
        });
    };
}
export function updateStatus() {
    return (dispatch) => {
        dispatch({
            type: UPDATE_STATUS,
        });
    };
}
export function updateStatusViaMQTT() {
    return (dispatch) => {
        dispatch({
            type: STATUS_UPDATE_VIA_MQTT,
        });
    };
}
export function setTvAppOutdated() {
    return (dispatch) => {
        dispatch({
            type: SET_TV_APP_OUTDATED,
        });
    };
}
export function setMessagesNotShown(val) {
    return (dispatch) => {
        dispatch({
            type: MESSAGES_NOT_SHOWN,
            payload: val,
        });
    };
}
export function setMessages(val) {
    return (dispatch) => {
        dispatch({
            type: SET_MESSAGES,
            payload: val,
        });
    };
}
export function updatePowerState(val) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_POWER_STATUS,
            payload: val,
        });
    };
}
export function centerFullScreenMsg(val) {
    return (dispatch) => {
        dispatch({
            type: CENTER_FULL_SCREEN_MESSAGE,
            payload: val,
        });
    };
}
