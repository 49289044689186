import Query from "../../query";
import { SESSION } from "../../../utils/session";
import { PERMISSION } from "../../../utils/constants";
import { movie, movieVisualizations } from "../../../utils/movies";
import { inPreview } from "../../../utils/utils";

type CallOptions = {
    stayGuestRoomId: number;
    last24h: boolean;
};

interface CallRentedOptions extends CallOptions {
    movie: movie;
}

export const getRentedMovies = (
    options: CallOptions,
    onSuccess?: (moviesIds: { extId: number; createdAt: Date }[] | null) => void,
) => {
    if (inPreview()) {
        if (onSuccess) {
            onSuccess([]);
        }
        return [];
    }
    if (SESSION.hasPermission(PERMISSION.NEW_MOVIES)) {
        // query for new movies
    } else {
        Query({
            query: `{
            rentedMovies(stayGuestRoomId:${options.stayGuestRoomId}, last24h: ${options.last24h}){
                movies{
                    extId
                    createdAt
                }
            }
        }`,
            onResponse(res) {
                if (res?.data?.rentedMovies?.movies) {
                    let parseResponse: { extId: number; createdAt: Date }[] = parseRentedMovies(
                        res?.data?.rentedMovies?.movies,
                    );
                    if (onSuccess) {
                        onSuccess(parseResponse);
                    }
                    return parseResponse;
                } else if (res && (!res.data || (res.data && !res.data.rentedMovies))) {
                    if (onSuccess) {
                        onSuccess([]);
                    }
                    return [];
                } else if (res?.error) {
                    console.log("HAY ERRORES");
                }
            },
            onError() {
                console.log("ERROORR  onError");
            },
        });
    }
};

const parseRentedMovies = (
    rentedMovies: { extId: string; createdAt: string }[],
): { extId: number; createdAt: Date }[] => {
    let parseResponse: { extId: number; createdAt: Date }[] = [];
    rentedMovies.forEach((element: { extId: string; createdAt: string }) => {
        // parse ID and removes Z from date
        parseResponse.push({ extId: parseInt(element.extId), createdAt: new Date(element.createdAt.slice(0, -1)) });
    });
    return parseResponse;
};

export const updateMovieTimes = (options: CallRentedOptions, onSuccess?: (movie: movie | null) => void) => {
    if (SESSION.hasPermission(PERMISSION.NEW_MOVIES)) {
    } else {
        Query({
            query: `{
            rentedMovies(stayGuestRoomId:${options.stayGuestRoomId}, last24h: ${options.last24h}){
                movies{
                    extId
                    createdAt
                }
            }
            movieVisualizations(stayGuestRoomId:${options.stayGuestRoomId}, movieId: ${options.movie?.id}, orderBy: {field:"startTime", criteria: "DESC"} ){
                percentageReached
                startTime
                visualizationTime
            }
        }`,
            onResponse(res) {
                if (res?.data?.rentedMovies?.movies && res?.data?.movieVisualizations) {
                    let parseResponse: { extId: number; createdAt: Date }[] = parseRentedMovies(
                        res?.data?.rentedMovies?.movies,
                    );
                    const movieUpdated = updateMovie(parseResponse, options.movie, res?.data?.movieVisualizations);
                    if (onSuccess) {
                        onSuccess(movieUpdated);
                    }
                } else if (res && (!res.data || (res.data && !res.data.rentedMovies))) {
                    if (onSuccess) {
                        onSuccess(null);
                    }
                } else if (res?.error) {
                    console.log("HAY ERRORES");
                }
            },
            onError() {
                console.log("ERROORR  onError");
            },
        });
    }
};

const updateMovie = (
    rentedMovies: { extId: number; createdAt: Date }[],
    movie: movie,
    times: movieVisualizations[],
): movie => {
    const isRented = rentedMovies.find((rented) => rented.extId === movie.id);
    console.log(isRented);
    if (isRented) {
        movie.rented = true;
        movie.visualizations = times;
        movie.createdAt = isRented.createdAt;
    } else {
        movie.rented = false;
    }

    return movie;
};
