import { useEffect, useRef, useState } from "preact/hooks";
import { figmapx2vh, figmapx2vw } from "../../../utils/utils";
import PlayerBar from "./playerBar";
import Button from "../../../components/common/button";
import { PLAY_STATUS, TV_MODELS } from "../../../utils/constants";
import { Media } from "../../../hooks/apis/media";
import focus from "../../../utils/focus";
import { eventHandler } from "../../../utils/eventHandler";
import { KEYS } from "../../../utils/keys";
import { STB } from "../../../hooks/apis/stb";
import { useContext } from "react";
import { PlayerContext } from "../player";
import { SESSION } from "../../../utils/session";

// start 10s jump of seek time
const SEEK_TIMES = [10, 30, 60, 120, 300];
const NUM_TIMES = 3;
const FORWARD = "forward";
const BACKWARD = "backward";

const BottomBar = ({ movie, secondsOnPauseRef, restartMenuTimeout, lastMovieTimeRef }) => {
    const [press, setPress] = useState(false);

    const { playStatus, setPlayStatus } = useContext(PlayerContext);

    const pressBtnInterval = useRef();
    const seekTimes = useRef();
    const updateSecondsInterval = useRef();
    seekTimes.current = 0;
    const multiplier = useRef();
    const playStatusRef = useRef();
    const lastTimePressDown = useRef();
    const isPressHolding = useRef();
    isPressHolding.current = null;

    multiplier.current = 0;

    const getStatusIcon = () => {
        if (focus.value.current === "btn-status") {
            return null;
        }
        switch (playStatus) {
            case PLAY_STATUS.playing:
                return "pause";
            default:
                return "play";
        }
    };
    const getStatusIconFocus = () => {
        switch (playStatus) {
            case PLAY_STATUS.playing:
                return "icon-pause-focus";
            default:
                return "icon-play-focus";
        }
    };

    const resumePauseVideo = () => {
        switch (playStatusRef.current) {
            case PLAY_STATUS.playing:
                pause();
                break;
            case PLAY_STATUS.pause:
                unPause();
                break;
            default:
                break;
        }
    };

    const pause = () => {
        Media.pause();
        setPlayStatus(PLAY_STATUS.pause);
    };
    const unPause = () => {
        Media.resume();
        setPlayStatus(PLAY_STATUS.playing);
    };

    useEffect(() => {
        focus.value.replace("btn-status");
        updateSecondsInterval.current = setInterval(function () {
            if (playStatusRef.current === PLAY_STATUS.pause) {
                secondsOnPauseRef.current = (secondsOnPauseRef.current || 0) + 1;
            }
        }, 1000);

        return () => {
            clearInterval(updateSecondsInterval.current);
            stopInterval();
        };
    }, []);

    useEffect(() => {
        playStatusRef.current = playStatus;
    }, [playStatus]);

    const handleMoveFast = (direction) => {
        if (playStatusRef.current === PLAY_STATUS.pause) {
            unPause();
        }
        addSeekTime();
        if (direction === FORWARD) {
            lastMovieTimeRef.current = parseInt(Media.getStreamPos() + SEEK_TIMES[multiplier.current]);
        } else if (direction === BACKWARD) {
            lastMovieTimeRef.current = parseInt(Media.getStreamPos() - SEEK_TIMES[multiplier.current]);
        }
        SESSION.cStreamPos = lastMovieTimeRef.current;
        Media.setStreamPos(lastMovieTimeRef.current);
    };

    const addSeekTime = () => {
        seekTimes.current++;
        if (seekTimes.current % NUM_TIMES === 0) {
            multiplier.current++;
        }
        if (multiplier.current > SEEK_TIMES.length - 1) {
            multiplier.current = SEEK_TIMES.length - 1;
        }
    };

    const pressButton = () => {
        if (STB.model === TV_MODELS.TIZEN) {
            createInterval();
        } else {
            if (isPressHolding?.current && new Date().getTime() - isPressHolding.current <= 1000) {
                // do nothing - too fast
            } else if (
                isPressHolding?.current &&
                new Date().getTime() - isPressHolding.current > 1000 &&
                new Date().getTime() - isPressHolding.current <= 1100
            ) {
                // add times to seek FF & FR
                isPressHolding.current = new Date().getTime();
                // prevent play - pause interval
                if (focus.value.current !== "btn-status") {
                    doActionsOfButtons();
                }
            } else {
                isPressHolding.current = new Date().getTime();
                // reset times to seek FF & FR
                stopInterval();
                doActionsOfButtons();
            }
        }
    };

    const doActionsOfButtons = () => {
        restartMenuTimeout();
        if (focus.value.current === "btn-forward") {
            handleMoveFast(FORWARD);
        } else if (focus.value.current === "btn-backward") {
            handleMoveFast(BACKWARD);
        } else if (focus.value.current === "btn-status") {
            if (!STB.noKeyUpSupport) {
                eventHandler.eventsBlocked = true;
            }
            resumePauseVideo();
        }
    };

    const createInterval = () => {
        doActionsOfButtons();
        if (focus.value.current === "btn-status") {
            return;
        }
        pressBtnInterval.current = setInterval(() => {
            doActionsOfButtons();
        }, 500);
    };

    const onBottomBarKeyDown = (e) => {
        if (!STB.noKeyUpSupport && eventHandler.eventsBlocked) {
            eventHandler.stopPropagation(e);
            return;
        }
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;

        if (keyData) {
            switch (keyData.value) {
                case KEYS.enter:
                    pressButton();
                    preventDefault = true;
                    break;
                case KEYS.right:
                    if (focus.value.current === "btn-forward") {
                        !movie.isAdult && focus.value.replace("btn-audio");
                        preventDefault = true;
                    }
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    const stopInterval = () => {
        seekTimes.current = 0;
        multiplier.current = 0;
        clearInterval(pressBtnInterval.current);
    };

    const onBottomBarKeyUp = (e) => {
        eventHandler.eventsBlocked = false;
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData) {
            switch (keyData.value) {
                case KEYS.enter:
                    stopInterval();
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            className={"w-full absolute bottom-0 z-10"}
            style={{ padding: `${figmapx2vw(40)} ${figmapx2vh(60)}`, height: `20vh`, display: "table-column" }}
        >
            <div
                style={{
                    position: "relative",
                    width: "13vw",
                    display: "table-cell",
                    verticalAlign: "middle",
                    paddingTop: "5vh",
                }}
                onKeyDown={(e) => {
                    onBottomBarKeyDown(e);
                }}
                onKeyUp={(e) => {
                    onBottomBarKeyUp(e);
                }}
            >
                <div className={"w-1/3 mx-auto "} style={{ display: "inline-table", verticalAlign: "top" }}>
                    <Button
                        data={{
                            id: "backward",
                            icon: focus.value.current === "btn-backward" ? null : "backward",
                            iconSize: "text-5xl",
                            customClass: "text-center mx-auto pt-1/2",
                            customStyle: {
                                width: figmapx2vw(64),
                                height: figmapx2vw(64),
                                borderRadius: "50%",
                                color: "#F5F6F8",
                                backgroundColor: "transparent",
                            },

                            focusClass: "grid-btn-back-focus icon-backward text-5xl text-center",
                        }}
                    />
                </div>
                <div className={"w-1/3 mx-auto "} style={{ display: "inline-table", verticalAlign: "top" }}>
                    <Button
                        data={{
                            id: "status",
                            icon: getStatusIcon(),
                            iconSize: "text-5xl",
                            customClass: "text-center mx-auto",
                            customStyle: {
                                width: figmapx2vw(64),
                                height: figmapx2vw(64),
                                borderRadius: "50%",
                                color: "#F5F6F8",
                                backgroundColor: "transparent",
                                paddingTop: "0.5vh",
                            },

                            focusClass: `grid-btn-back-focus ${getStatusIconFocus()} text-5xl text-center`,
                        }}
                    />
                </div>
                <div className={"w-1/3 mx-auto "} style={{ display: "inline-table", verticalAlign: "top" }}>
                    <Button
                        data={{
                            id: "forward",
                            icon: focus.value.current === "btn-forward" ? null : "forward",
                            iconSize: "text-5xl",
                            customClass: "text-center mx-auto pt-1/2",
                            customStyle: {
                                width: figmapx2vw(64),
                                height: figmapx2vw(64),
                                borderRadius: "50%",
                                color: "#F5F6F8",
                                backgroundColor: "transparent",
                            },

                            focusClass: "grid-btn-back-focus icon-forward text-5xl text-center",
                        }}
                    />
                </div>
            </div>
            <div style={{ display: "table-cell", paddingTop: movie?.isAdult && "8vh" }}>
                <PlayerBar movie={movie} />
            </div>
        </div>
    );
};

export default BottomBar;
