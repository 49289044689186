import React, { createContext, useEffect, useRef, useState } from "react";
import Limbo from "../../components/screen/limbo";
import focus from "../../utils/focus";
import {
    figmapx2vh,
    figmapx2vw,
    formatSeconds,
    getLanguageNameFromIso,
    getStayGuestRoomId,
    setVideoOnBackground,
    setVideoOnForeground,
} from "../../utils/utils";
import Button from "../../components/common/button";
import { MOVIE_DETAIL_DISPLAY, PATHS, PLAY_STATUS, POWER, TV_MODELS, VISIBILITY } from "../../utils/constants";
import Query from "../../hooks/query";
import { useDispatch, useSelector } from "react-redux";
import { Logger } from "../../utils/logger";
import { eventHandler } from "../../utils/eventHandler";
import { setMediaEvent, setNumberPress } from "../../actions/eventsActions";
import { KEYS } from "../../utils/keys";
import { Media } from "../../hooks/apis/media";
import { displayErrorPopUp, setVideoPos } from "../../actions/uiActions";
import { EVENTS } from "../../utils/eventsConst";
import TopBar from "./player/topBar";
import BottomBar from "./player/bottomBar";
import { sendDisplayTime } from "../../hooks/GraphqlCalls/movies/sendDisplayTime";
import { getIso6391 } from "../../utils/iso639/ISO639";
import { SESSION } from "../../utils/session";
import { filterMovieTracks, isMovieFullyWatched, setCurrentAudioTrack, SUBTITLE_DIV_ID } from "../../utils/movies";
import { STB } from "../../hooks/apis/stb";
import { navigate } from "wouter/use-location";

const Player = ({ movie, backToMovieDetail, setRefreshMovieData }) => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);

    const MENU_TIMEOUT = 4000;
    const LAST_TIME_TIMEOUT = 10000;

    const popup = useSelector((state) => state.ui.popup);
    const { sessionData, powerStatus } = useSelector((state) => state.status);
    const reducerMediaEvent = useSelector((state) => state.events.mediaEvent);
    const appVisibility = useSelector((state) => state.ui.appVisibility);

    const [startTime, setStartTime] = useState(new Date().toISOString());
    const [showMenu, setShowMenu] = useState(true);
    const [menuBlocked, setMenuBlocked] = useState(false);
    const [errorPlaying, setErrorPlaying] = useState(false);
    const [playStatus, setPlayStatus] = useState(PLAY_STATUS.playing);

    const menuTimeout = useRef();
    const lastMovieTimeInterval = useRef();
    const lastMovieTimeRef = useRef();
    const showMenuRef = useRef();
    const secondsOnPauseRef = useRef(0);
    const startVisualizationTime = useRef();
    const playStatusRef = useRef();
    const menuBlockedRef = useRef();
    const videoSuccessRef = useRef();

    showMenuRef.current = showMenu;
    playStatusRef.current = playStatus;
    menuBlockedRef.current = menuBlocked;

    const isMovieForceAudio = Media.checkForceAudio(movie);

    useEffect(() => {
        Media.stop();
        setVideoOnBackground();
        if (isMovieForceAudio) {
            Media.playHLSVideo(movie.forceAudioUrl.replace("{{lang}}", setCurrentAudioTrack(movie.languages)));
        } else {
            Media.playHLSVideo(movie.url);
        }
        setTimeout(() => {
            dispatch(setVideoPos("fullscreen"));
        }, 100);

        startMovieTimeInterval();
        focus.value.replace("btn-status");

        return () => {
            setVideoOnForeground();
            clearInterval(lastMovieTimeInterval.current);
            clearTimeout(menuTimeout.current);
            saveMovieTimes();
            setRefreshMovieData(true);
            dispatch(setVideoPos({ x: 0, y: 0, width: 0.1, height: 0.1 }));
            Media.stop();
            SESSION.cStreamPos = 0;
            SESSION.cAudioTrackId = null;
        };
    }, []);

    useEffect(() => {
        if (appVisibility === VISIBILITY.HIDDEN || powerStatus == POWER.OFF) {
            navigate(PATHS.HOME);
        }
    }, [appVisibility, powerStatus]);

    useEffect(() => {
        if (popup == "alarmActive") {
            videoSuccessRef.current = false;
        }
    }, [popup]);

    useEffect(() => {
        if (errorPlaying) {
            setShowMenu(false);
            setVideoOnForeground();
            dispatch(
                displayErrorPopUp({
                    text: texts["content-not-available"],
                    onClose: () => {
                        backToMovieDetail();
                    },
                    timeout: 3000,
                }),
            );
        }
    }, [errorPlaying]);

    useEffect(() => {
        if (reducerMediaEvent?.code === EVENTS.MEDIA.STATUS_END_OF_STREAM) {
            backToMovieDetail();
            dispatch(setVideoPos({ x: 0, y: 0, width: 0.1, height: 0.1 }));
            dispatch(setMediaEvent(null));
        } else if (reducerMediaEvent?.code === EVENTS.MEDIA.PLAY_ERROR) {
            setErrorPlaying(true);
            dispatch(setMediaEvent(null));
        } else if (reducerMediaEvent?.code === EVENTS.MEDIA.PLAY_SUCCESS) {
            if (videoSuccessRef.current) {
                dispatch(setMediaEvent(null));
                return;
            }
            if (!popup) {
                videoSuccessRef.current = true;
            }
            onPlayStartActions();
            setErrorPlaying(false);
            dispatch(setMediaEvent(null));
        }
    }, [reducerMediaEvent]);

    useEffect(() => {
        showMenuRef.current = showMenu;
        if (showMenu) {
            restartMenuTimeout();
        } else {
            if (!document.querySelector("#movie_menu") && !popup) {
                focus.value.replace("moviePlayerLimbo");
            }
        }
    }, [showMenu]);

    useEffect(() => {
        playStatusRef.current = playStatus;
    }, [playStatus]);

    useEffect(() => {
        playStatusRef.current = playStatus;
    }, [menuBlocked]);

    const saveMovieTimes = () => {
        const secondsOnPlayer = Math.round(
            (new Date().getTime() - new Date(startVisualizationTime.current).getTime()) / 1000,
        );
        const effectiveTime = secondsOnPlayer - secondsOnPauseRef.current;
        sendDisplayTime({
            movie: movie,
            stayGuestRoomId: getStayGuestRoomId(sessionData),
            startTime: startVisualizationTime.current,
            visualizationTime: effectiveTime,
            lastMovieTime: lastMovieTimeRef.current || SESSION.cStreamPos,
        });
    };

    const startMovieTimeInterval = () => {
        startVisualizationTime.current = new Date().toISOString();

        lastMovieTimeInterval.current = setInterval(function () {
            lastMovieTimeRef.current = Media.getStreamPos();
            //check movie length
            if (lastMovieTimeRef.current > movie.length * 60) {
                backToMovieDetail();
            }
            if (lastMovieTimeRef.current > 0) {
                SESSION.cStreamPos = lastMovieTimeRef.current;
            }
        }, LAST_TIME_TIMEOUT);
    };

    const restartMenuTimeout = () => {
        setShowMenu(true);

        clearTimeout(menuTimeout.current);
        menuTimeout.current = setTimeout(function () {
            if (playStatusRef.current == PLAY_STATUS.pause || menuBlockedRef.current) {
                restartMenuTimeout();
                return;
            }
            setShowMenu(false);
        }, MENU_TIMEOUT);
    };

    const onPlayStartActions = () => {
        //restore current position after video stopped by an alarm
        if (SESSION.cStreamPos > 0) {
            Media.setStreamPos(SESSION.cStreamPos, 3000);
            //Set video pos from last visualization record time
        } else if (movie?.visualizations?.[0]?.percentageReached && !isMovieFullyWatched(movie.visualizations)) {
            const newPos = parseInt((movie.length * 60 * movie?.visualizations?.[0]?.percentageReached) / 100);
            Media.setStreamPos(newPos, 3000);
            lastMovieTimeRef.current = newPos;
        }
        if (SESSION.cSubtitleTrackId) {
            Media.changeSubtitle(SESSION.cSubtitleTrackId, movie);
        } else {
            Media.disableSubtitles();
        }

        if (!STB.autoSetLangOnVideoPlay && !isMovieForceAudio) {
            //set guest language
            const setGuestAudioLang = (list) => {
                if (!list) {
                    return;
                }
                list = filterMovieTracks(list, movie.languages, isMovieForceAudio);

                if (SESSION.cAudioTrackId) {
                    Media.changeAudio(SESSION.cAudioTrackId);
                } else {
                    // guest
                    let audioGuestOk = setMovieLangTo(list, localStorage.getItem("lang"));
                    if (!audioGuestOk) {
                        // project default lang
                        let audioProjectOk = setMovieLangTo(list, SESSION.projectDefaultLang);
                        if (!audioProjectOk) {
                            // english
                            setMovieLangTo(list, "en");
                        }
                    }
                }
            };
            Media.getAudioList(setGuestAudioLang);
        }
    };

    const setMovieLangTo = (list, langIso) => {
        let changeToLang = false;
        list?.forEach((audio) => {
            if (getIso6391(audio.iso) === getIso6391(langIso)) {
                SESSION.cAudioTrackId = audio.id;
                Media.changeAudio(audio.id);
                changeToLang = true;
                return;
            }
        });
        return changeToLang;
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (!showMenuRef.current && keyData) {
            if (keyData.value == KEYS.home) {
                return;
            } else if (keyData.value == KEYS.back) {
                backToMovieDetail();
            } else {
                restartMenuTimeout();
            }
            eventHandler.stopPropagation(e);
            return;
        }
        restartMenuTimeout();

        if (keyData) {
            switch (keyData.value) {
                case KEYS.enter:
                    break;

                case KEYS.back:
                    if (playStatus === PLAY_STATUS.pause) {
                        Media.resume();
                        setPlayStatus(PLAY_STATUS.playing);
                    }
                    preventDefault = true;
                    setShowMenu(false);
                    break;
                case KEYS.up:
                case KEYS.down:
                    break;
                case KEYS.home:
                    setShowMenu(false);
                    return;

                default:
                    //   preventDefault = true;
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    const unPause = (callback) => {
        Media.resume();
        setPlayStatus(PLAY_STATUS.playing);
        if (callback) {
            callback();
        }
    };

    return (
        <PlayerContext.Provider
            value={{
                showMenu,
                setShowMenu,
                menuBlocked,
                setMenuBlocked,
                movie,
                isPlayer: true,
                restartMenuTimeout,
                playStatus,
                setPlayStatus,
                videoSuccessRef,
                unPause,
            }}
        >
            <div
                id={SUBTITLE_DIV_ID}
                style={{
                    position: "absolute",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                    bottom: "12vh",
                    fontSize: "3.34vw",
                    zIndex: 1,
                }}
            ></div>
            <div
                onKeyDown={(e) => {
                    onkeydown(e);
                }}
            >
                <Limbo id={"moviePlayerLimbo"} noBlocker={true} />
                {showMenu ? (
                    <div
                        id="movie_menu"
                        style={{
                            width: "100vw",
                            height: "100vh",
                            background: "linear-gradient(to bottom, transparent, #373D42)",
                            color: "#F5F6F8",
                            zIndex: 10,
                        }}
                    >
                        <TopBar movie={movie} backToMovieDetail={backToMovieDetail} />
                        <BottomBar
                            movie={movie}
                            secondsOnPauseRef={secondsOnPauseRef}
                            restartMenuTimeout={restartMenuTimeout}
                            lastMovieTimeRef={lastMovieTimeRef}
                        />
                    </div>
                ) : null}
            </div>
        </PlayerContext.Provider>
    );
};

export default Player;

export const PlayerContext = createContext({
    showMenu: null,
    setShowMenu: null,
    menuBlocked: null,
    setMenuBlocked: null,
    playStatus: null,
    setPlayStatus: null,
    isPlayer: null,
    movie: null,
    restartMenuTimeout: null,
});
