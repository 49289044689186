import { useEffect, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import { Media } from "../../hooks/apis/media";
import { Texts } from "../../utils/texts";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";
import { figmapx2vh, figmapx2vw, getLanguageNameFromIso } from "../../utils/utils";
import { KEYS } from "../../utils/keys";
import { memo, useContext, useMemo, useRef } from "react";
import { STB } from "../../hooks/apis/stb";
import { filterMovieTracks } from "../../utils/movies";
import { PlayerContext } from "../movies/player";
import { TV_MODELS } from "../../utils/constants";
import { SESSION } from "../../utils/session";

const ChannelSubtitleSelector = ({ playingChannel, toggleMenuState }) => {
    const { showMenu, setShowMenu, setMenuBlocked, isPlayer, movie, restartMenuTimeout } = useContext(PlayerContext);

    //States
    const [subtitles, setSubtitles] = useState(null);
    const [currentSubtitle, setCurrentSubtitle] = useState(0);
    const [selector, showSelector] = useState(false);
    const [showtext, setShowText] = useState(false);
    const [active, isActive] = useState(false);

    const currentSubtitleRef = useRef();
    currentSubtitleRef.current = currentSubtitle;

    const subtitlesRef = useRef();
    subtitlesRef.current = subtitles;
    const SELECTOR_ID = "subtitleSelector";
    const filterSubtitles = isPlayer;
    //Store data
    const texts = useSelector((state) => state.ui.texts);
    useEffect(() => {
        //restore subtitle
        if (STB.hideSubtitlesOnMenu && eventHandler.lastSubtitleId != null && !isPlayer) {
            Media.changeSubtitle(eventHandler.lastSubtitleId);
        }

        focus.subscribe((value) => {
            isActive(value.current === "btn-subtitle" || value.current === SELECTOR_ID);
        });
    }, []);

    useEffect(() => {
        if (selector) {
            setMenuBlocked && setMenuBlocked(true);
            toggleMenuState && toggleMenuState();
            showSubtitleList();
        } else {
            if (setMenuBlocked) {
                setMenuBlocked(false);
                restartMenuTimeout();
            }
        }
    }, [selector]);

    useEffect(() => {
        setCurrentSubtitle(0);
        setSubtitles(null);
    }, [playingChannel]);

    const showSubtitleList = () => {
        const onSubtitleList = (list) => {
            if (!list) {
                list = [];
            }
            if (filterSubtitles) {
                list = filterMovieTracks(list, movie.subtitles);
            }

            list.unshift({
                id: -1,
                name: Texts.capitalize(texts[list?.length > 0 ? "no subtitles" : "unavailable"]),
            });
            setSubtitles(list);
            setTimeout(function () {
                focus.value.replace(SELECTOR_ID);
            }, 50);
        };

        if (isPlayer && movie) {
            let movieSubs = [...movie.subtitles];
            movieSubs.unshift({
                id: -1,
                name: Texts.capitalize(texts["no subtitles"]),
            });
            setSubtitles(movieSubs);
            setTimeout(function () {
                focus.value.replace(SELECTOR_ID);
            }, 50);
        } else {
            Media.getSubtitleList(onSubtitleList);
        }
    };

    const isFocused = (id) => {
        return focus.value.current === id;
    };

    const hideSelector = () => {
        showSelector(false);
        toggleMenuState && toggleMenuState();
        setTimeout(function () {
            focus.value.replace("btn-subtitle");
        }, 20);
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        if (keyData) {
            switch (keyData.value) {
                case KEYS.up:
                    setCurrentSubtitle(subtitles?.[currentSubtitle + 1] ? currentSubtitle + 1 : 0);
                    break;
                case KEYS.down:
                    setCurrentSubtitle(subtitles?.[currentSubtitle - 1] ? currentSubtitle - 1 : subtitles?.length - 1);
                    break;
                case KEYS.enter:
                    hideSelector();
                    SESSION.cSubtitleTrackId = subtitlesRef.current?.[currentSubtitleRef.current].id;
                    if (subtitlesRef.current?.[currentSubtitleRef.current].id === -1) {
                        SESSION.cSubtitleTrackId = null;
                        Media.disableSubtitles();
                        eventHandler.lastSubtitleId = null;
                    } else {
                        eventHandler.lastSubtitleId = subtitlesRef.current?.[currentSubtitleRef.current].id;
                        if (isPlayer && movie) {
                            Media.changeSubtitle(subtitlesRef.current?.[currentSubtitleRef.current].id, movie);
                        } else {
                            Media.changeSubtitle(subtitlesRef.current?.[currentSubtitleRef.current].id);
                        }
                    }
                    break;
                case KEYS.back:
                    hideSelector();
                    break;
                default:
                    break;
            }
        }
        eventHandler.stopPropagation(e);
    };

    return useMemo(() => {
        return (
            <>
                <div
                    id="btn-subtitle"
                    onClick={() => showSelector(true)}
                    tabIndex={0}
                    focusClass="channelList-focus"
                    className={`icon icon-subtitles relative navigable ${
                        active && focus.value.current !== "btn-subtitle"
                            ? "iconActive"
                            : focus.value.current === "btn-subtitle"
                            ? "channelList-focus"
                            : ""
                    }`}
                    style={{ marginRight: `${figmapx2vw(32)}`, lineHeight: `${figmapx2vh(isPlayer ? 64 : 80)}` }}
                >
                    <div className={`lato ${active ? "iconNameFocused" : "hidden"}`}>
                        {Texts.capitalize(texts["subtitles"])}
                    </div>
                    {selector ? (
                        <>
                            <div
                                onKeyDown={(e) => {
                                    onkeydown(e);
                                }}
                                id={`${SELECTOR_ID}`}
                                focusClass="channelList-focus"
                                tabIndex={0}
                                className={"selector w-full table navigable absolute channelList-focus text-center"}
                                style={{
                                    fontSize: `${figmapx2vw(30)}`,
                                    fontWeight: 400,
                                    height: !subtitles || subtitles?.length === 1 ? `${figmapx2vh(104)}` : null,
                                }}
                            >
                                <div className={"table-cell lato vertical-middle"} style={{ lineHeight: 1 }}>
                                    {getLanguageNameFromIso(subtitles?.[currentSubtitle]?.name) || texts["unavailable"]}

                                    {subtitles?.length > 1 ? (
                                        <div>
                                            <div className="icon icon-chevron-up absolute" />
                                            <div className="icon icon-chevron absolute" />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </>
        );
    });
};

export default memo(ChannelSubtitleSelector);
