/* eslint-disable no-undef */
import { PATHS } from "../../../utils/constants";
import { dispatchNewMediaEvent, EVENTS } from "../../../utils/eventsConst";
import { SUBTITLE_BASE_URL } from "../../../utils/movies";
import { getLanguageNameFromIso, vh2px, vw2px, writeLog } from "../../../utils/utils";
import { philips } from "./philips";

export const Media = {
    init() {
        this.stop();
    },
    _stop() {
        philips.stopChannel();
        philips.removeVideoPane();
    },

    setVideoSize(x, y, width, height) {
        x = vw2px(x);
        y = vh2px(y);
        width = vw2px(width);
        height = vh2px(height);

        philips.setVideoPaneSize(x, y, width, height);
    },
    _playChannel(ip, port) {
        this._stop();
        philips.changeChannelIp(ip, port);
    },
    removeVideoPane() {
        philips.removeVideoPane();
    },
    getAudioList(onSuccess) {
        const onListAction = (list) => {
            if (!list) {
                onSuccess(null);
                return;
            }
            let audioList = [];
            const audios = list.AudioLanguageList;
            audios.map((audio, index) =>
                audioList.push({ id: index, name: getLanguageNameFromIso(audio.Language), iso: audio.Language }),
            );
            onSuccess(audioList);
        };

        philips.getAudioTracks(onListAction);
    },
    getSubtitleList(onSuccess) {
        const onListAction = (list) => {
            if (!list) {
                onSuccess(null);
                return;
            }
            let subtitleList = [];
            const subtitles = list.SubtitleLanguageList;
            subtitles.map((subtitle, index) =>
                subtitleList.push({
                    id: index,
                    name: getLanguageNameFromIso(subtitle.Language),
                }),
            );

            onSuccess(subtitleList);
        };

        philips.getSubtitles(onListAction);
    },
    changeAudio(ind) {
        philips.setAudioTrack(Number(ind));
    },
    changeSubtitle(ind, movie) {
        if (movie) {
            var video = philips.VIDEOPANE;
            const oldsubtitle = document.getElementById("subtitleSrt");
            if (oldsubtitle) {
                this.disableVideoObjectSubtitles();
                document.body.removeChild(oldsubtitle);
            }
            const subSelected = movie.subtitles.find((sub) => sub.id === ind);
            const subtitleSrt = document.createElement("script");
            subtitleSrt.id = "subtitleSrt";
            subtitleSrt.type = "text/srt";
            subtitleSrt.dataset.label = subSelected.name;
            subtitleSrt.dataset.lang = subSelected.iso6391;
            subtitleSrt.src = `${SUBTITLE_BASE_URL}${subSelected.file}`;
            document.body.appendChild(subtitleSrt);

            var subtitle = document.getElementById("subtitleSrt");
            var track = video.addTextTrack("subtitles", subtitle.dataset.label, subtitle.dataset.lang);
            track.mode = "showing";
            fetch(subtitleSrt.src)
                .then((response) => response.text())
                .then((response) => {
                    subtitleSrt.innerHTML = response;
                    Media.quick_and_dirty_vtt_or_srt_parser(subtitleSrt.innerHTML).map(function (cue) {
                        cue.line = 13;
                        track.addCue(cue);
                    });
                });
        } else {
            philips.setSubtitles("On", Number(ind));
        }
    },
    quick_and_dirty_vtt_or_srt_parser(vtt) {
        const parse_timestamp = (s) => {
            //var match = s.match(/^(?:([0-9]{2,}):)?([0-5][0-9]):([0-5][0-9][.,][0-9]{0,3})/);
            // Relaxing the timestamp format:
            var match = s.match(/^(?:([0-9]+):)?([0-5][0-9]):([0-5][0-9](?:[.,][0-9]{0,3})?)/);
            if (match == null) {
                throw "Invalid timestamp format: " + s;
            }
            var hours = parseInt(match[1] || "0", 10);
            var minutes = parseInt(match[2], 10);
            var seconds = parseFloat(match[3].replace(",", "."));
            return seconds + 60 * minutes + 60 * 60 * hours;
        };
        var lines = vtt
            .trim()
            .replace("\r\n", "\n")
            .split(/[\r\n]/)
            .map(function (line) {
                return line.trim();
            });
        var cues = [];
        var start = null;
        var end = null;
        var payload = null;
        for (var i = 0; i < lines.length; i++) {
            if (lines[i].indexOf("-->") >= 0) {
                var splitted = lines[i].split(/[ \t]+-->[ \t]+/);
                if (splitted.length != 2) {
                    throw 'Error when splitting "-->": ' + lines[i];
                }

                // Already ignoring anything past the "end" timestamp (i.e. cue settings).
                var html5VideoAdjustTimeRetardation = 2;
                // object video of html5 has some delay rendering subtitles, so need to remove 2s start and end time of cue
                start = parse_timestamp(splitted[0]) - html5VideoAdjustTimeRetardation;
                end = parse_timestamp(splitted[1]) - html5VideoAdjustTimeRetardation;
            } else if (lines[i] == "") {
                if (start && end) {
                    var cue = new VTTCue(start, end, payload);
                    cues.push(cue);
                    start = null;
                    end = null;
                    payload = null;
                }
            } else if (start && end) {
                if (payload == null) {
                    payload = lines[i];
                } else {
                    payload += "\n" + lines[i];
                }
            }
        }
        if (start && end) {
            var cue = new VTTCue(start, end, payload);
            cues.push(cue);
        }
        return cues;
    },
    disableSubtitles() {
        philips.setSubtitles("Off", 0);
        this.disableVideoObjectSubtitles();
    },

    disableVideoObjectSubtitles() {
        var videoObject = document.querySelector("video");
        if (videoObject) {
            var listTracks = videoObject.textTracks;
            for (let index = 0; index < listTracks.length; index++) {
                const track = listTracks[index];
                track.mode = "hidden";
            }
        }
    },

    _playHLSVideo(url) {
        //url = "https://channel01.akamaized.net/hls/live/2022749/event01/index.m3u8";
        //url = "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8";
        //url = "https://zafiro.cloud/das/stream/video-ec7531be-4b2e-45aa-961a-cc343576e5cc-636861696e2d303030302d303030302d303030302d30303039-en_hd.m3u8";

        writeLog(`Would play HLS: ${url}`);
        try {
            if (philips.VIDEOPANE) {
                philips.removeVideoPane();
            }

            const isFullScreenPath = () => {
                return window.location.pathname === PATHS.TV || window.location.pathname === PATHS.MOVIES;
            };

            const videoSize = {
                x: isFullScreenPath() ? window.screen.width : 1,
                y: isFullScreenPath() ? window.screen.height : 1,
            };

            philips.createVideoPane("application/vnd.apple.mpegurl", 0, 0, videoSize.x, videoSize.y, 100);
            philips.VIDEOPANE.src = url;
            philips.showVideoPane();
            philips.VIDEOPANE.play();

            philips.VIDEOPANE.addEventListener("ended", (resp) => {
                dispatchNewMediaEvent(EVENTS.MEDIA.STATUS_END_OF_STREAM);
            });
            philips.VIDEOPANE.addEventListener("seeking", (resp) => {
                this.seeking = true;
            });
            philips.VIDEOPANE.addEventListener("seeked", (resp) => {
                this.seeking = false;
            });

            philips.VIDEOPANE.addEventListener("playing", () => {
                dispatchNewMediaEvent(EVENTS.MEDIA.PLAY_SUCCESS);
                //set movie language
            });
        } catch (e) {
            console.error(`Error playing VoD: ${e.message}`, e);
        }
    },
    _playDVBTChannel: function (ch) {
        console.debug("DVBT channel");
        philips.changeChannelRf(ch.frequency, ch.isc);
    },
    pause() {
        philips.VIDEOPANE.pause();
    },
    resume() {
        philips.VIDEOPANE.play(1);
    },
    getStreamPos() {
        if (!philips.VIDEOPANE) {
            return null;
        }
        return philips.VIDEOPANE.currentTime;
    },
    setStreamPos(newpos, delay) {
        if (!philips.VIDEOPANE || this.seeking) {
            return;
        }
        if (delay) {
            setTimeout(() => {
                philips.VIDEOPANE.currentTime = newpos;
            }, delay);
        } else {
            philips.VIDEOPANE.currentTime = newpos;
        }
    },
    checkForceAudio: function (movie) {
        return true;
    },
};
